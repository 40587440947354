import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Layout from "../components/layout"
import ReferenceList from "../components/referenceList"
import { Container } from "../components/utils"
import SEO from "../components/seo"
import Intro from "../components/intro"
import Footer from "../components/footer"

const ReferencesPage = ({ data }) => (
  <Layout>
    <SEO title={data.page.title} lang="it" />
    <Header>
      <h1>{data.page.title}</h1>
      <h2>{data.page.subtitle}</h2>
    </Header>
    <Intro title={data.page.title} />
    <Container>
      <ReferenceList references={data.references.nodes} />
    </Container>
    <Footer />
  </Layout>
)

export default ReferencesPage

export const query = graphql`
  query ReferencesQuery {
    page: datoCmsReferencesPage {
      title
      subtitle
      slug
    }
    references: allDatoCmsReference(sort: { fields: position }) {
      nodes {
        client
        project
        period
        location
        id
        slug
        description
        sector {
          title
        }
        category {
          id
          name
        }
        gallery {
          url
          alt
        }
      }
    }
  }
`
